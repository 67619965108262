<template>
  <div>
    <v-dialog v-model="dialog" max-width="700" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">
          <v-icon
            color="gifty"
            size="50"
            class="animate__animated animate__rotateIn"
            >mdi-message-processing
          </v-icon>
          <h2 class="text--gifty mt-3">Renvoyer un sms</h2>

          <v-btn
              color="whatsapp"
              large
              depressed
              class="rounded-lg text-none mt-3"
              @click="copySms()"
            >
              <v-icon left class="" style="">mdi-content-copy</v-icon>
              Copier
            </v-btn>
          <v-alert
            border="left"
            color="error"
            dark
            v-if="error != null"
            class="mt-3"
          >
            {{ error }}
          </v-alert>

          <p class="mt-5 mb-5">Voulez-vous vraiment renvoyer le SMS ?</p>
          <v-btn
            text
            class="rounded-lg mr-2 text-none"
            large
            @click="cancel()"
            v-if="!loading"
          >
            <v-icon left>mdi-close</v-icon> Annuler
          </v-btn>
          <v-btn
            class="rounded-lg text-none white--text"
            large
            color="gifty"
            depressed
            @click="confirm('sms')"
            :loading="loading"
          >
            <v-icon left class="white--text">mdi-check-circle-outline</v-icon>
            Confirmer
          </v-btn>

          <v-divider class="mt-3" />
          <p class=" text-center darken-2 font-weight-bold mt-2" >Ou bien Envoyer Par</p>
          <div class="d-flex justify-center">
            <v-btn
              color="whatsapp"
              large
              depressed
              class="rounded-lg text-none white--text mt-3 mr-2"
              style="background-color: #25d366 !important"
              @click="sendWhatsAppSms"
            >
              <v-icon left class="white--text">mdi-whatsapp</v-icon>
              WhatsApp
            </v-btn>

            <v-btn
              color="whatsapp"
              large
              depressed
              class="rounded-lg text-none white--text mt-3 mr-2"
              style="background-color: #7360f2 !important"
              @click="sendViberAppSms()"
            >
              <v-icon left class="white--text">mdi-phone-in-talk</v-icon>
              Viber
            </v-btn>

            <v-btn
              color="whatsapp"
              large
              depressed
              class="rounded-lg text-none white--text mt-3"
              style="background-color: #229ed9 !important"
              @click="sendTelegramAppSms()"
            >
              <v-icon left class="white--text" style="transform: rotate(330deg);">mdi-send</v-icon>
              Telegram
            </v-btn>
            

            <v-btn
              color="whatsapp"
              large
              depressed
              class="rounded-lg text-none white--text ml-2 mt-3 gifty"
              @click="confirm('gifty')"
            >
              <img src="../../../assets/logo.svg" width="30"/>
              Gifty
            </v-btn>

          
            
          </div>

          <div>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      is_confirm: false,
      confirm_dialog: false,
      item: null,
      error: null,

      textSms: null,
      gifty_choice: false
    };
  },
  methods: {
    open(item) {
      this.item = item;
      this.dialog = true;
      this.getSmsText();
    },
    confirm(choice) {
      this.loading = true;
      this.error = null;

      HTTP.post("/v1/support/resendSms/" + this.item.id, {
        choice: choice
      })
        .then(() => {
          this.loading = false;
          this.dialog = false;
          this.$successMessage = "Sms Renvoyé avec succès";
          this.$emit("refresh");
          this.gifty_choice = false
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.error = err.response.data.message;
          this.gifty_choice = false
          
        });
    },

    getSmsText(){
      this.loading = false;
      HTTP.get("/v1/support/geSmsText/" + this.item.id)
        .then((res) => {
          this.loading = false;
          this.textSms = res.data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.error = err.response.data.message;
        });
    },

    getPhoneItem(){
      var phone = null;
      if(this.item.voucher){
        phone = this.item.voucher.phone;
      }

      if(this.item.idoom_card){
        phone = this.item.idoom_card.phone;
      }

      return phone;
    },

    sendWhatsAppSms() {
      
      var url = "https://wa.me/"+this.getPhoneItem()+"?text="+encodeURI(this.textSms);

      window.open(url, '_blank');
    },

    sendViberAppSms() {
      window.open(this.getViberLink(), '_blank');
    },

    sendTelegramAppSms() {
      var url= "tg://resolve?phone="+this.getPhoneItem()+"&text="+encodeURI(this.textSms);
      window.open(url, '_blank');
    },

    getViberLink(){
      var url = "viber://chat?number="+this.getPhoneItem()+"&text="+encodeURI(this.textSms);
      return url;
    },

    copySms(){
      navigator.clipboard.writeText(this.textSms);
      this.$successMessage = "Le SMS a été copié"
    },

    cancel() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
</style>