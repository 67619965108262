<template>
  <v-dialog max-width="500" v-model="dialog">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>
        Vérification de SMS
        <v-spacer/>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>

      <v-card-text class="pa-8">
        {{ result }}
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      isLoading: false,
      result: {},
    }
  },
  methods: {
    open(item) {
      this.dialog = true
      this.checkSmsStatus(item.flexy_id)
    },
    checkSmsStatus(flexy_id) {
      this.isLoading = true
      HTTP.post("/v1/support/check-sms-status", {
        flexy_id: flexy_id
      }).then((res) => {
        this.result = res.data.data
        this.isLoading = false
      }).catch((e) => {
        console.log(e)
        this.isLoading = false
      })
    }
  }
}
</script>
