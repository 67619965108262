<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="650"
    >

      <v-card :disabled="btnLoading"
              :loading="btnLoading">

        <v-card-title>
          Modifier numero de téléphone
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">


          <v-tabs v-model="tab" centered>
            <v-tab>Modification</v-tab>
            <v-tab @click="getHistories">Historiques</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-10">
                  <v-text-field v-model="form.old_phone_number"
                                :error-messages="errors.old_phone_number"
                                append-icon="mdi-phone-outline"
                                disabled
                                label="Numéro actuel *"
                  ></v-text-field>

                  <v-text-field v-model="form.new_phone_number"
                                :error-messages="errors.new_phone_number"
                                append-icon="mdi-phone-outline"
                                label="Nouveau numéro *"
                  ></v-text-field>

                  <v-textarea v-model="form.note"
                              :error-messages="errors.note"
                              hide-details="auto"
                              label="Remarque"
                              rows="2"
                  ></v-textarea>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text class="pa-4">

                  <v-skeleton-loader v-if="isLoading" type="list-item-three-line,list-item-three-line"/>

                  <div v-else>

                    <v-simple-table v-if="phones.length" class="table-border">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>Ancien</th>
                          <th>Nouveau</th>
                          <th>Remarque</th>
                          <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(phone,i) in phones" :key="i">
                          <td class="font-weight-medium text-no-wrap">
                            <v-icon :style="{marginTop:'-3px'}"
                                    small>mdi-close-circle
                            </v-icon>
                            {{ phone.old_phone_number }}
                          </td>
                          <td class="font-weight-medium success--text text-no-wrap">
                            <v-icon :style="{marginTop:'-3px'}"
                                    color="success"
                                    small>mdi-check-circle
                            </v-icon>
                            {{ phone.new_phone_number }}
                          </td>
                          <td>{{ phone.note ? phone.note : '-' }}</td>
                          <td class="text-no-wrap">
                            <v-icon :style="{marginTop:'-3px'}"
                                    color="gifty"
                                    small>mdi-clock
                            </v-icon>
                            {{ phone.created_at }}
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <div v-else class="text-center">
                      <v-icon size="60">mdi-text-box-search-outline</v-icon>
                      <p class="py-3">Aucun enregistrement trouvé !!</p>
                    </div>

                  </div>


                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs-items>

        </v-card-text>


        <v-divider v-if="!tab"/>

        <v-card-actions v-if="!tab">
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="primary"
                 depressed
                 type="submit"
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            tab: 0,
            dialog: false,
            isLoading: false,
            btnLoading: false,
            form: {
                transaction_id: null,
                old_phone_number: null,
                new_phone_number: null,
                note: null,
                transactionType: null,
            },
            errors: {},
            phones: [],
        }
    },
    methods: {
        open(item) {

            this.dialog = true
            this.errors = {}

            let number = null
            let transactionType = null

            if (item.idoom_card) {
                number = item.idoom_card.phone ?? null
                transactionType = 'card'
            }

            if (item.voucher) {
                number = item.voucher.phone ?? null
                transactionType = 'voucher'
            }

            this.form = {
                transaction_id: item.id,
                transaction_type: transactionType,
                old_phone_number: number,
                new_phone_number: null,
                note: null,
            }
        },
        close() {
            this.dialog = false
        },
        save() {
            this.errors = {}
            let url = '/v1/support/update-phone-number'
            let data = this.form
            this.btnLoading = true
            HTTP.post(url, data).then(() => {
                this.btnLoading = false
                this.$successMessage = "Ce numéro de téléphone a été mofidié avec succès."
                this.close()
                this.$emit('refresh')
            }).catch(err => {
                this.btnLoading = false
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur s'est produite veuillez corriger."
                console.log(err)
            })
        },
        getHistories() {
            this.isLoading = true
            let url = '/v1/support/phone-numbers-history-list'
            HTTP.get(url, {
                params: {
                    transaction_id: this.form.transaction_id
                }
            }).then((res) => {
                this.isLoading = false
                this.phones = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
    }
}
</script>

