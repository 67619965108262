<template>
  <v-dialog max-width="500" scrollable v-model="dialog" persistent>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-btn text color="grey" v-bind="attrs" v-on="{ ...menu }">
        <v-icon left>mdi-filter-outline</v-icon> Filtre
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Filtrer par
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="loading">
        <v-skeleton-loader type="article, actions" />
      </v-card-text>
      <v-card-text class="pa-4" v-else>
        <v-row>
          <v-col cols="12">
            <p class="d-flex justify-center">
              <v-radio-group
                v-model="filter.payment_mode"
                row
                hide-details=""
                class="mt-0"
              >
              <v-radio key="all" label="Tout" value="">
                </v-radio>
                <v-radio :key="'gifty'" :label="'Gifty'" :value="'gifty'">
                  <template v-slot:label>
                    <img src="@/assets/logo2.png" style="width: 80px" />
                  </template>
                </v-radio>

                <v-radio :key="'cib'" :label="'CIB'" :value="'cib'">
                  <template v-slot:label>
                    <img src="@/assets/carte_CIB.jpg" style="width: 80px" />
                  </template>
                </v-radio>
              </v-radio-group>
            </p>

            <v-autocomplete
              multiple
              chips
              deletable-chips
              small-chips
              clearable
              :items="users"
              v-model="filter.accounts"
              :item-text="'name'"
              :item-value="'account.id'"
              label="Compte..."
            >
            </v-autocomplete>

            <v-select
              multiple
              chips
              deletable-chips
              small-chips
              clearable
              :items="types"
              v-model="filter.types"
              :item-text="'name'"
              :item-value="'id'"
              label="Type..."
            >
            </v-select>

            <v-select
              multiple
              chips
              deletable-chips
              small-chips
              clearable
              :items="status"
              v-model="filter.status"
              :item-text="'label'"
              :item-value="'value'"
              label="Status"
              hide-details
            >
            </v-select>
          </v-col>

          <v-col v-if="in_array(filter.types, 'sent_refill')">
            <v-autocomplete
              multiple
              chips
              deletable-chips
              small-chips
              clearable
              :items="users"
              v-model="filter.emetters"
              :item-text="'name'"
              :item-value="'account.id'"
              label="Emetteur..."
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col v-if="in_array(filter.types, 'received_refill')">
            <v-autocomplete
              multiple
              chips
              deletable-chips
              small-chips
              clearable
              :items="users"
              v-model="filter.receptters"
              :item-text="'name'"
              :item-value="'account.id'"
              label="Recepteur..."
              hide-details
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-select
              multiple
              chips
              deletable-chips
              small-chips
              clearable
              :items="services"
              v-model="filter.services"
              :item-text="'name'"
              :item-value="'id'"
              label="Services..."
              hide-details
            >
              <template v-slot:item="slotProps">
                <div style="width: 50px">
                  <img
                    :src="getImageLink(slotProps.item.image)"
                    max-height="25"
                    max-width="25"
                    width="25"
                    height="25"
                    class="p-2"
                  />
                </div>

                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="filter.amount"
              label="Montant..."
              type="number"
              hide-details
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            v-if="
              in_array(filter.types, 'voucher') ||
              in_array(filter.types, 'Idoom') ||
              in_array(filter.types, '4G')
            "
          >
            <v-chip-group v-model="filter.with_sms" column>
              <v-chip filter color="blue" outlined value="-1">Tous</v-chip>
              <v-chip filter color="warning" outlined value="0"
                >Sans SMS</v-chip
              >
              <v-chip filter color="success " outlined value="1"
                >Avec SMS</v-chip
              >
            </v-chip-group>

            <v-checkbox label="Aucun sms envoyé" v-model="filter.no_sms"/>
          </v-col>

          <v-col cols="12">
            <v-combobox
              class="mt-3"
              label="Téléphone "
              v-model="filter.phone"
              hide-details
              :items="phones"
              dense
            ></v-combobox>
          </v-col>

          <v-col cols="12" v-if="in_array(filter.types, 'voucher')">
            <v-select
              prepend-icon="mdi-filter-outline"
              multiple
              chips
              deletable-chips
              small-chips
              dense
              clearable
              :items="suppliers"
              v-model="filter.suppliers"
              :item-text="'name'"
              :item-value="'id'"
              label="Suppliers..."
              hide-details
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-dialog
              ref="dialogStartDate"
              v-model="modalStartDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filter.startDate"
                  label="Date début"
                  hide-details
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filter.startDate"
                @input="[(filter.endDate = null), (modalStartDate = false)]"
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-dialog
              ref="dialogEndDate"
              v-model="modalEndDate"
              @click="modalEndDate = false"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filter.endDate"
                  label="Date fin"
                  hide-details
                  :disabled="!filter.startDate"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="modalEndDate = false"
                v-model="filter.endDate"
                :min="filter.startDate"
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!loading">
        <v-btn
          color="primary"
          :disabled="isDisabled"
          depressed
          block
          @click="handleFilter"
        >
          Recherche
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { HTTP } from "@/http-common";
export default {
  props: ["filter"],
  data() {
    return {
      dialog: false,

      modalStartDate: false,
      modalEndDate: false,

      loading: false,
      loadingUsers: false,

      // Types
      types: [
        {
          id: "flexy",
          name: "Flexy",
        },
        {
          id: "sent_refill",
          name: "Solde envoyé",
        },
        {
          id: "received_refill",
          name: "Solde reçu",
        },
        {
          id: "voucher",
          name: "Voucher",
        },
        {
          id: "Idoom",
          name: "Carte Idoom",
        },
        {
          id: "4G",
          name: "Carte 4G",
        },
        {
          id: "refund",
          name: "Remboursé",
        },
      ],

      status: [
        {
          label: "En attente",
          value: "PENDING",
        },

        {
          label: "Acceptée",
          value: "OK",
        },
        {
          label: "Refusée",
          value: "REFUNDED",
        }
      ],

      phones: [],
      users: [],
    };
  },
  methods: {
    handleFilter() {
      this.dialog = false;
      this.$emit("filter", this.filter);
    },
    clearFilter() {
      this.props.filter = {};
    },

    getPhones() {
      this.loading = true;
      HTTP.get("/v1/support/phones")
        .then((res) => {
          this.phones = res.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    getUsers() {
      this.loadingUsers = true;
      HTTP.get("/v1/support/users")
        .then((res) => {
          this.users = res.data;
          this.loadingUsers = false;
        })
        .catch((e) => {
          console.log(e);
          this.loadingUsers = false;
        });
    },
    getServices() {
      this.$store.dispatch("fetchServices", {
        filter: {
          size: 1000,
        },
      });
    },
  },
  computed: {
    isDisabled() {
      return Object.values(this.filter).every(
        (x) => x === null || x === "" || x.length === 0
      );
    },
    suppliers() {
      return this.$store.getters.getSuppliers;
    },
    owners() {
      return this.$store.getters.getVoucherOwners;
    },
    services() {
      return this.$store.getters.getServices.data;
    },
  },

  watch: {
    dialog() {
      if (this.phones.length == 0) {
        this.getPhones();
      }

      if (this.users.length == 0) {
        this.getUsers();
      }

      this.getServices();
    },
  },

  mounted() {},
};
</script>
