<template>
    <v-dialog v-model="dialog"
              max-width="1000"
              scrollable>
        <v-card :disabled="cancelLoading" :loading="cancelLoading">

            <v-card-title>
                {{ title }}
                <v-spacer/>
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>

            <v-divider/>

            <v-skeleton-loader v-if="isLoading" type="table"/>

            <v-card-text v-else class="pa-0">

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-no-wrap">ID</th>
                            <th class="text-no-wrap">Nom</th>
                            <th class="text-no-wrap">Date de transaction</th>
                            <th class="text-no-wrap">Montant</th>
                            <th class="text-no-wrap">Frais de service</th>
                            <th class="text-no-wrap">Promotion</th>
                            <th class="text-no-wrap">Code promo</th>
                            <th class="text-no-wrap">Total</th>
                            <th class="text-no-wrap">Type</th>
                            <th class="text-no-wrap">Service</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,i) in transactions" :key="i"
                        >
                            <td class="text-no-wrap">{{ item.id }}</td>

                            <td class="text-no-wrap font-weight-medium pointer"
                                @click="showAccount(item.account_id)">
                                <v-avatar size="35">
                                    <v-img :src="require('@/assets/avatar.png')"/>
                                </v-avatar>

                                {{
                                item.account ? item.account.user ? item.account.user.name : "pas de user" : "pas de compte"
                                }}
                            </td>

                            <td class="text-no-wrap">
                                <v-icon :style="{marginTop:'-3px'}"
                                        color="gifty"
                                        small>mdi-clock-outline
                                </v-icon>
                                {{ item.created_at }}
                            </td>

                            <td class="text-no-wrap font-weight-medium text-primary">
                                {{ CurrencyFormatting(item.amount) }}
                            </td>

                            <td class="text-no-wrap font-weight-medium gifty--text text-center">
                          <span v-if="item.transaction_fee">
                            {{ CurrencyFormatting(item.transaction_fee.fee_amount) }}
                          </span>
                                <span v-else> - </span>
                            </td>

                            <td class="text-no-wrap font-weight-medium gifty--text text-center">
                          <span v-if="item.transaction_promotion">
                            {{ CurrencyFormatting(item.transaction_promotion.promotion_amount) }}
                          </span>
                                <span v-else> - </span>
                            </td>

                            <td class="text-no-wrap font-weight-medium gifty--text text-center">
                          <span v-if="item.transaction_promo_code">
                            {{ CurrencyFormatting(item.transaction_promo_code.promo_code_amount) }}
                          </span>
                                <span v-else> - </span>
                            </td>

                            <td>
                                <v-chip color="success" dark small>
                                    {{ CurrencyFormatting(item.total_amount) }} DZD
                                </v-chip>
                            </td>

                            <td class="text-no-wrap">

                                <v-btn v-if="item.type === 'sent_refill'"
                                       block
                                       class="text-none"
                                       color="grey"
                                       outlined
                                       rounded
                                       small
                                       text>
                                    <v-icon color="indigo" left>
                                        mdi-arrow-up-right-bold
                                    </v-icon>
                                    Sode envoyé
                                </v-btn>

                                <v-btn v-if="item.type === 'received_refill'"
                                       block
                                       class="text-none"
                                       color="grey"
                                       outlined
                                       rounded
                                       small
                                       text>
                                    <v-icon color="success" left>
                                        mdi-arrow-down-left-bold
                                    </v-icon>
                                    Sode reçu
                                </v-btn>

                                <v-btn v-if="item.type === 'voucher'"
                                       block
                                       class="text-none"
                                       color="grey"
                                       outlined
                                       rounded
                                       small
                                       text>
                                    <v-icon color="primary" left>
                                        mdi-ticket-percent
                                    </v-icon>
                                    Voucher
                                </v-btn>

                                <v-btn v-if="item.type === 'flexy'"
                                       block
                                       class="text-none"
                                       color="grey"
                                       outlined
                                       rounded
                                       small
                                       text>
                                    <v-icon color="gifty" left>
                                        mdi-cellphone-wireless
                                    </v-icon>
                                    Flexy
                                </v-btn>

                                <v-btn v-if="item.type === 'Idoom' || item.type === '4G'"
                                       block
                                       class="text-none"
                                       color="grey"
                                       outlined
                                       rounded
                                       small
                                       text>
                                    <v-icon color="info" left>
                                        mdi-card-multiple
                                    </v-icon>
                                    Idoom
                                </v-btn>

                            </td>

                            <td class="text-no-wrap">
                                {{ item.service != null ? item.service.name : "-" }}
                            </td>

                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </v-card-text>

            <v-divider v-if="!isLoading && total > 10"/>

            <v-card-actions v-if="!isLoading && total > 10">

                <div :style="{width : '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined
                    ></v-select>
                </div>

                <v-spacer/>

                <v-pagination v-if="total > 10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              circle
                              total-visible="8"
                              @input="getDoubleTransactions"></v-pagination>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            transactions: [],
            dialog: false,
            cancelLoading: false,
            isLoading: false,
            title: null,

            total: 0,
            perPage: 10,
            pagination: {
                current: 1,
                total: 0
            },
            type: null

        };
    },
    methods: {
        open(title, type) {
            this.dialog = true
            this.title = title
            this.type = type
            this.getDoubleTransactions()
        },
        getDoubleTransactions() {
            this.isLoading = true
            const url = this.type ? "double-transactions-by-time" : "double-transactions"
            HTTP.get("/v1/support/" + url + '?page=' + this.pagination.current, {
                params: {
                    perPage: this.perPage
                }
            }).then((res) => {
                this.pagination.current = res.data.data.current_page;
                this.pagination.total = res.data.data.last_page;
                this.total = res.data.data.total;

                this.transactions = res.data.data.data
                this.isLoading = false
            }).catch((e) => {
                console.log(e);
                this.isLoading = false
            })
        },
        showAccount(account_id) {
            let route = this.$router.resolve({path: "/users/" + account_id});
            window.open(route.href, "_blank");
        },
    },
    watch: {
        perPage() {
            this.getDoubleTransactions()
        }
    },
};
</script>
